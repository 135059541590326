<template>
    <div class="box form-box has-background-white">
        <div class="enso-form">
            <enso-form ref="form"/>
        </div>
    </div>
</template>

<script>
import { EnsoForm } from '@enso-ui/forms/bulma';

export default {
    name: 'Edit',

    components: { EnsoForm },
};
</script>
